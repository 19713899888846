/* global ScrollTrigger */
import {
    $, addAction, INIT, HASH_STATE_CHANGE,
} from '@situation/setdesign.util';

const easeOutExpo = (x) => (x === 1 ? 1 : 1 - 2 ** (-10 * x));
const maxTime = 800;
const easeRate = 30;

/* eslint-disable no-param-reassign */
const processBatch = (batch, add) => {
    window.requestAnimationFrame(() => {
        batch.forEach((element, index) => {
            if (add) {
                const delay = Math.round(maxTime * easeOutExpo(index / easeRate)) + 100;
                if (delay) {
                    element.style.transitionDelay = `${delay}ms`;
                    element.style.animationDelay = `${delay}ms`;
                }

                element.classList.add('animate-to');
                element.classList.remove('animate-from');
            } else {
                element.style.transitionDelay = '';
                element.style.animationDelay = '';
                element.classList.add('animate-from');
                element.classList.remove('animate-to');
            }
        });
    });
};

const forceUpdate = () => {
    setTimeout(() => {
        window.requestAnimationFrame(() => {
            ScrollTrigger.refresh(false);
            ScrollTrigger.update();
        });
    }, 0);
};

/* eslint-enable no-param-reassign */
const selectors = ['.content-column__inner', '.graphical-representation__technique [style]'];
addAction(INIT, () => {
    $(selectors.join(', ')).addClass('animate-from');
    ScrollTrigger.batch(selectors.join(', '), {
        onEnter: (batch) => processBatch(batch, true),
        // onLeave: (batch) => processBatch(batch, false),
        // onEnterBack: (batch) => processBatch(batch, true),
        onLeaveBack: (batch) => processBatch(batch, false),
    });

    addAction('updateScrollTrigger', forceUpdate);
    addAction(HASH_STATE_CHANGE, forceUpdate);
    addAction('toggleTrigger', ($targets) => {
        const elements = $targets
            .find('.animate-to')
            .filter((i, el) => SetDesign.viewport.isInViewport(el))
            .get();
        if (elements.length) {
            processBatch(elements, false);
            window.requestAnimationFrame(() => {
                processBatch(elements, true);
            });
        }
    });
});
