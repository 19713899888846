import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const $firstTab = $('.dynamic-tactic .sub-nav__item').first();
    const $heading = $('.targets-progress-heading');
    if (!$firstTab.length || !$heading.length) return;

    $heading.html(
        $heading
            .text()
            .split(' ')
            .map((w) => `<span>${w}</span>`)
            .join(' '),
    );

    const $words = $heading.find('span');
    addAction('toggleActive', (activeIds) => {
        const $selected = $(`a[href='#${activeIds}']`).parent();
        const method = $selected.is(':first-child') ? 'first' : 'last';
        $words.removeClass('text--primary')[method]().addClass('text--primary');
    });
});
